%none {
    display: none;
    visibility: hidden;
    width: 0;
    height: 0;
    font-size: 0;
}

.header-clone,
.woocommerce .product .button,
.homeProductSlider .product .button,
.blog .comments-area,
.blog .entry-header .meta-post,
.blog .entry-thumb,
.product-detail .woocommerce-breadcrumb,
.product-detail .product_meta,
.product-detail .price,
.product-detail .woocommerce-tabs,
.product-detail .related.products { 
    @extend %none;
}