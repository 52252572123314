.mod-section {
    padding: $distance__section 0 !important;
}
@for $i from 1 through 9 {
    .mod-mt--#{$i} {
        margin: {
            top: $i * 1em;
        }
    }
}
.mod-title {
    font: {
        weight: normal;
        size: $title-fz;
    }

    &.mod-title--caption {
        font: normal $title--caption-fz $GBold;
        color: $color__primary;

        > span {
            display: block;
            font: normal .6em $GRegular;
        }

        > strong {
            font: {
                weight: normal;
            }
        }
    }

    &.mod-title--white {
        color: $color__white;
    }
}
.color__black {
    color: black;
}