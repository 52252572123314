/* 
* TEXT
-------------------------------------------- */
.mod-text {
    &.mod-text--1 {
        .mod-text {
            &__title {
                font: {
                    size: $fz__7--5;
                    weight: normal;
                }
            }

            &__content {
                margin: {
                    top: $text--1__content-mt;
                }
                font-size: $fz__10;
            }

            &--white {
                color: white;
            }
        }
    }
}

/* 
* SOCIAL
---------------------------------------------------- */
.mod-social {
    .mod-social {
        &__icon {
            display: inline-block;
            margin: {
                right: $social__icon-mlr;
            }
        }
    }
}

/* 
* BTN
--------------------------------------------------- */
.mod-btn {
    .vc_btn3 {
        padding: $btn-p;
        background-color: transparent !important;
        border: {
            color: $color__primary !important;
        }
        color: $color__primary !important;

        &:hover {
            border: {
                color: transparent !important;
            }
        }
    }
}

/* 
* BANNER
------------------------------------------------------------------- */
.mod-banner {
    position: relative;

    .mod-banner {
        &__wrap {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-100%, -50%);
        }
        
        &__title,
        &__slogan {
            color: $color__white;
        }

        &__title {
            font: normal #{ $fz__70 }/.8 $GBold;
            @include media(1024px) {
                font-size: $fz__70 / 2;
            }
            @include media(420px) {
                font-size: $fz__70 / 3;
            }
        }

        &__slogan {
            font: normal #{ $fz__15 }/1.4 $GRegular;
            @include media(420px) {
                font-size: $fz__15 / 2;
            }

            > small {
                display: block;
                color: inherit;
            }
        }
    }
}

/* 
* FORM
------------------------------------------------- */
.mod-form {
    @include absolute(50%, 'h-center');
    z-index: 999;
    min-width: 280px;
    padding: 1em;
    background: {
        color: white;
    }
    box-shadow: 0 4px 8px rgba($color__black, .8);
    opacity: 0;
    visibility: hidden;

    &.mod-form--active {
        visibility: visible;
        opacity: 1;
        transition: opacity .6s;
    }

    .mod-form {
        &__title {
            margin: {
                bottom: 1em;
            }
            font: normal $fz__15 $GBold;
            color: $color__primary;
        }
    }

    .wpcf7 {
        input {
            &[type="text"],
            &[type="email"],
            &[type="tel"] {
                width: 100%;
                @extend %form__input;
            }

            &[type="submit"] {
                @extend %form__submit;
            }
        }

        textarea {
            width: 100%;
            resize: none;
            @extend %form__input;
        }
    }
}