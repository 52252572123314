@include font('GRegular', 'gothic-1');
@include font('GBold', 'gothicb0-1');

.site-header {
    position: absolute;
    padding: 0;
    background-color: transparent;
    
    &.fixed {
        position: absolute;
        background-color: transparent;
        @include media(1024px) {
            position: absolute !important;
        }
        @include media(420px) {
            position: static !important;
        }
    }

    .site-header {
        &__top {
            padding: $headTop__ptb 0;
            background-color: $color__primary;
            @include media(800px) {
                display: none;
            }
        }

        &__content {
            float: left;
            overflow: hidden;
            
            li {
                list-style: none;
            }
        }

        &__icon {
            float: left;
            margin: {
                right: $headTop__icon-mr;
            }
            color: $color__white;
            font: {
                size: $headTop__fz;
            }
            
            &:before {
                content: '';
                display: inline-block;
                width: $headTop__icon-wh;
                height: $headTop__icon-wh;
                margin: {
                    right: $headTop__icon-mr / 2;
                }
                background: url(#{$headTop__icon-src}) center top/100% auto no-repeat;
                vertical-align: middle;
            }
        }

        @each $i in tel, email {
            &__icon--#{$i} {
                &:before {
                    @if $i == tel {
                        background: {
                            position-y: 0;
                        }
                        
                    } @else {
                        background: {
                            position-y: $headTop__iconEmailY;
                        }
                    }
                }
            }
        }

        &__social {
            float: right;
        }
    }

    .header-wrap {
        .col-md-4.col-sm-8.col-xs-6,
        .col-md-8.col-sm-4.col-xs-6 {
            position: static;
            @include media(420px) {
                margin: {
                    bottom: 0;
                }
            }
        }
        
        .site-logo {
            @include absolute(0, "h-center");
            max-height: 80px;
            @include media(420px) {
                position: static;
                transform: translateX(0);
                max-height: 50px;
            }
        }
    }

    @include media(1024.1px, "min") {
        #mainnav {
            &:after {
                content: '';
                display: table;
                clear: both;
            }
            
            .menu {
                > .menu-item {
                    padding: {
                        left: $menu__item-plr !important;
                        right: $menu__item-plr !important;
                    }
                    
                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3) {
                        float: left !important;
                    }
    
                    &:nth-child(4),
                    &:nth-child(5),
                    &:nth-child(6) {
                        float: right !important;
                    }
    
                    &:first-child {
                        padding: {
                            left: 0 !important;
                        }
                    }
    
                    &:nth-last-child(2) {
                        padding: {
                            right: 0 !important;
                        }
                    }

                    > a {
                        position: relative;
                        display: inline-block;
                        padding: {
                            bottom: .2em;
                        }
                        text-transform: uppercase;

                        &:after {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            width: 80%;
                            height: 1px;
                            background: {
                                color: $color__primary;
                            }
                            transform-origin: right center;
                            transform: scale(0, 1);
                            transition: transform .5s,
                            color .5s;
                        }


                        &:hover {
                            color: $color__primary;
                        
                            &:after {
                                transform: scale(1);
                            }
                        }
                    }

                    &.current-menu-item > a,
                    &.current-page-item > a {
                        font: {
                            family: $GBold;
                        }
                        color: $color__primary !important;
                    
                        &:after {
                            transform: scale(1);
                        }
                    }
                }
            }

            .sub-menu {
                .menu-item {
                    padding: 0 !important;

                    > a {
                        &:hover {
                            color: $color__white;
                        }
                    }
                }
            }
        }
    }
    @include media(1024px) {
        .btn-menu {
            margin: {
                top: 5%;
            }
            @include media(800px) {
                margin: {
                    top: 10%;
                }
            }
            @include media(420px) {
                margin: {
                    top: 0;
                }
                line-height: 1;
                color: $color__primary;
            }
        }
        #mainnav-mobi {
            left: 0;
            top: 130px;
            @include media(420px) {
                top: auto;
            }
        }
    }
}
.footer-widgets {
    padding: $distance__section 0;
    
    .widget {
        padding: {
            top: 0;
        }

        &:last-child {
            margin: {
                bottom: 0;
            }
            @include media(420px) {
                margin: {
                    bottom: $fw__widget-mb * 2;
                }
            }
        }
    }

    .textwidget {
        .text {
            display: inline-block;
            
            &:first-child {
                margin: {
                    right: $fw__text-mr;
                }
            }
        }
    }

    .widget-title {
        padding: {
            bottom: $fw__title-pb;
        }
        font: {
            size: $fw__fz--2;
            weight: normal;
        }
    }

    .widget_nav_menu {
        margin: {
            bottom: $fw__widget-mb;
        }
    }

    .menu {
        .menu {
            &-item {
                line: {
                    height: $fw__menuItem-lineHeight;
                }

                > a {
                    font: {
                        size: $fw__fz--2 !important;
                    }
                }
            }

        }

        .sub-menu {
            display: none;
            visibility: hidden;
        }
    }

    .custom-html-widget {
        ul li {
            padding: {
                top: 0;
                bottom: 0;
            }
        }
    }

    .widget_media_image {
        .image {
            max-height: 80px;
            width: auto;
        }
    }
}
.site-footer {
    .site-footer {
        &__copyright {
            font: {
                size: $footer__fz;
            }
            line: {
                height: 1.4;
            }
            @include media(420px) {
                text-align: center;
            }
        }
        
        &__inspiren {
            float: right;
            @include media(420px) {
                float: none;
                display: block;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

/* 
* clients
--------------------------------------------------- */
.mod-clients {
    position: relative;
    background: url('../images/mod_clents_bg@1x.jpg') center/cover no-repeat;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: {
            color: rgba($color__gray--1, .9);
        }
    }
    
    .sa_hover_container {
        padding: {
            top: 0 !important;
            bottom: 0 !important;
        }
        min-height: 0 !important;
    }

    .mod-clients {
        &__wrapper {
            position: relative;
            z-index: 2;
        }

        &__title {
            font: {
                weight: normal;
                size: $clients__title-fz;
            }
            color: $color__white;

            > strong {
                font: {
                    weight: normal;
                    family: $GBold;
                }
            }
        }

        &__slider {
            .mod-clients {
                &__portrait {
                    display: block;
                    width: $clients__portrait-wh !important;
                    height: $clients__portrait-wh !important;
                    margin: {
                        left: auto;
                        right: auto;
                    }
                    border: 4px solid $color__primary {
                        radius: 50%;
                    }
                    object: {
                        fit: cover;
                    }
                }
        
                &__name {
                    font: normal $clients__name-fz $GBold;
                    color: $clients--color;
                }
        
                &__introduce {
                    font: {
                        size: $clients__introduce-fz;
                    }
                    color: $clients--color;
                }
        
                &__says {
                    font-size: $clients__name-fz;
                    color: $color__white;
                }
            }
        }
    }
}

/* 
* CONTACT
---------------------------------------------------------------- */
.mod-contact {
    padding: $distance__section / 2 0;

    .mod-contact {
        &__wrap {
            padding: {
                top: $contact__wrap-pt;
                left: $contact__wrap-pl;
            }

            .wpcf7 {
                margin: {
                    top: $contact__form-mt;
                }
            }

            input {
                &[type="text"],
                &[type="tel"],
                &[type="email"] {
                    max-height: $contact__form-maxW;
                    width: 100%;
                    @extend %form__input;
                }

                &[type="submit"] {
                    @extend %form__submit;
                }
            }
            
            textarea {
                width: 100%;
                max-height: $contact__form-maxW;
                @extend %form__input;
                resize: none;
            }
        }
    }
}

/* 
* OWL CLIENTS SLIDERS
------------------------------------------------- */
#slider_111 .owl-nav div, 
#slider_111 .owl-nav button {
    width: 20px !important;
    height: 30px !important;
    background: {
        size: 50% auto !important;
    }
}