body,
html {
    position: relative;
    margin-top: 0 !important;
    padding: 0 !important;
    overflow-x: hidden;
    line-height: 1.4;
    font-size: 16px;
    @media screen and (max-width: 800px) {
        font-size: 14px;
    }
}
h1,h2,h3,
h4, h5, h6,
p, ul {
    margin: 0;
}

ul {
    padding: {
        left: 0;
    }
}

#wpadminbar,
.page .entry-header,
.entry-footer {
    display: none !important;
}
.content-area .hentry,
.page-wrap {
    padding: {
        top: 0 !important;
        bottom: 0 !important;
    }
}
.admin-bar .site-header.float-header,
.admin-bar .site-header {
    top: 0;
}
.page .page-wrap .content-wrapper, 
.single .page-wrap .content-wrapper {
    padding: 0;
}
.page-wrap .content-wrapper {
    padding: {
        top: 0;
        bottom: 0;
    }
}
.wpb_content_element {
    margin: {
        bottom: 0 !important;
    }
}
.vc_row-no-padding .vc_inner {
    margin: {
        left: auto !important;
        right: auto !important;
    }
}
.wpb_gmaps_widget .wpb_wrapper {
    padding: 0 !important;
}
.vc_section {
    margin: {
        left: 0;
        right: 0;
    }
}
.container {
    margin: {
        left: auto !important;
        right: auto !important;
    }
}
.wpb_column {
    > .vc_column-inner {
        padding: {
            top: 0 !important;
        }
    }
}