@mixin media($max__width, $min: false) {
    @if $min {
        @media screen and (min-width: $max__width) {
            @content;
        }
        
    } @else {
        @media screen and (max-width: $max__width) {
            @content;
        }
    }
}

@mixin opacity($opacity){
    opacity: $opacity;
    $opacity-id: $opacity * 100;
    filter:Alpha(opacity = $opacity-id); //IE8
}

@mixin font($name, $fileName) {
    @font-face {
        font-family: $name;
        src: url('../fonts/#{$fileName}.eot'); /* IE9+ */
        src: url('../fonts/#{$fileName}.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('../fonts/#{$fileName}.woff') format('woff'), /* chrome、firefox */
            url('../fonts/#{$fileName}.woff2') format('woff2'), 
            url('../fonts/#{$fileName}.ttf') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
            url('../fonts/#{$fileName}.svg#fontname') format('svg'); /* iOS 4.1- */
    }
}

@mixin flex($align: null) {
    display: flex;
    flex-wrap: wrap;

    @if $align == 'h-center' {
        justify-content: center;

    } @else if $align == 'v-center' {
        align-items: center;
        
    } @else if $align == 'vh-center' {
        justify-content: center;
        align-items: center;
    }
}

@mixin line ($h-v, $location: top, $lineWidth: 100%, $color: $color-promary){
    position: relative;

    @if $h-v {
        &:before {
            content: '';
            position: absolute;
            @if $location == top {
                top: 0;
            } @else if $location == bottom {
                bottom: 0;
            }
            left: 50%;
            @if $h-v == "h" {
                width: $lineWidth;
                height: 1px;
                transform: translateX(-50%);
                
            } @else if $h-v == "v" {
                width: 1px;
                height: $lineWidth;
            }
            background-color: $color;
        }
    }
}

@mixin coverColor ($color: $color-promary){
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: $color;
    }
}

@mixin absolute($top, $location) {

    @if $location == "h-center" {
        position: absolute;
        top: $top;
        left: 50%;
        margin: {
            bottom: 0;
        }
        transform: translateX(-50%);

    } @else {
        position: absolute;
        top: $top;
        left: $location;
        margin: {
            bottom: 0;
        }
        transform: translateX(-50%);
    }
}

// sin, cos and tan

$pi: 3.14159265359;
$_precision: 10;

@function pow($base, $exp) {
    $value: $base;
    @if $exp > 1 {
      @for $i from 2 through $exp {
        $value: $value * $base;
      }
    }
    @if $exp < 1{
      @for $i from 0 through -$exp {
        $value: $value / $base;
      }
    }
    @return $value;
}

@function fact($num) {
    $fact: 1;
    @if $num > 0{
      @for $i from 1 through $num {
        $fact: $fact * $i;
      }
    }
    @return $fact;
}

@function _to_unitless_rad($angle) {
    @if unit($angle) == "deg" {
      $angle: $angle / 180deg * $pi;
    }
    @if unit($angle) == "rad" {
      $angle: $angle / 1rad;
    }
    @return $angle;
}
  
@function sin($angle){
    $a: _to_unitless_rad($angle);
    $sin: $a;
    @for $n from 1 through $_precision {
      $sin: $sin + (pow(-1, $n) / fact(2 * $n + 1) ) * pow($a, (2 * $n + 1));
    }
    @return $sin;
}
  
@function cos($angle){
    $a: _to_unitless_rad($angle);
    $cos: 1;
    @for $n from 1 through $_precision {
      $cos: $cos + ( pow(-1,$n) / fact(2*$n) ) * pow($a,2*$n);
    }
    @return $cos;
}
  
@function tan($angle) {
    @return sin($angle) / cos($angle);
}

@function ellipseXY($xy, $index, $perAngle, $r) {
    $angle: $perAngle * $index + 180;

    @if $xy == x {
        @return floor( cos($angle) * $r );

    } @else {
        @return floor( sin($angle) * $r );
    }
}