/* 
* PROJECT All VARIABLES
*/

/* 
* COLOR
------------------------------------------------------------ */
$color__primary: #840539 !default;
$color__orange: #f7941e !default;
$color__black: black !default;
$color__gray--1: #414042 !default;
$color__gray--2: #464649 !default;
$color__gray--3: #58595b !default;
$color__gray--4: #bcbec0 !default;
$color__gray--5: #a7a9ac !default;
$color__gray--6: #f1f2f2 !default;
$color__white: white !default;

/* 
* FONT SIZE
------------------------------------------------------------ */
$fz__7--5: .75rem !default;
$fz__8: .8rem !default;
$fz__9: .9rem !default;
$fz__10: 1rem !default;
$fz__12: 1.2rem !default;
$fz__13: 1.3rem !default;
$fz__15: $fz__7--5 * 2 !default;
$fz__20: $fz__10 * 2 !default;
$fz__24: $fz__12 * 2 !default;
$fz__70: 7rem !default;

/* 
* FONT FAMILY 
------------------------------------------------------------- */
$GRegular: "GRegular", serif !default;
$GBold: "GBold", serif !default;

/* 
* DISTANCE
------------------------------------------------------------ */
$distance__section: 4em !default;

/* 
* COMPONENTS
------------------------------------------------------------- */
/* -- TEXT */
$text--1__content-mt: .5em !default;

/* -- SOCIAL */
$social__icon-mlr: .2em !default;

/* -- TITLE */
$title-fz: $fz__12 !default;
$title--caption-fz: $fz__20 !default;

/* -- BTN */
$btn-p: .2em 2em !default;

/* 
* PAGE
-------------------------------------------------------------- */
/* COMMON */
/* -- head top */
$headTop__fz: $fz__9 !default;
$headTop__ptb: .5em !default;
$headTop__icon-mr: 1em !default;
$headTop__icon-wh: 1em !default;
$headTop__icon-src: "../images/spr_mod_contact@2x.png" !default;
$headTop__iconEmailY: -1.5em !default;

/* -- head menu */
$menu__item-plr: 3% !default;

/* -- foot widgets */
$fw__widget-mb: 1em !default;
$fw__text-mr: 2em !default;
$fw__title-pb: 1em !default;
$fw__menuItem-lineHeight: 1.3 !default;
$fw__fz--1: $fz__10 !default;
$fw__fz--2: $fz__7--5 !default;

/* -- foot */
$footer__fz: $fz__8 !default;

/* -- CLIENTS SAYS */
$clients__title-fz: $fz__20 !default;
$clients__portrait-wh: 10em !default;
$clients__name-fz: $fz__12 !default;
$clients--color: $color__white !default;
$clients__introduce-fz: $fz__9 !default;

/* -- CONTACT */
$contact__wrap-pl: 5% !default;
$contact__wrap-pt: 1em !default;
$contact__form-mt: 2em !default;
$contact__form-maxW: 480px !default;

%form__input {
    background: {
        color: transparent;
    }
    border: {
        top: none;
        right: none;
        left: none;
        bottom-color: $color__gray--1;
    }
    font: {
        family: $GRegular;
    }
    color: $color__gray--1;
    
    &:focus {
        outline: 0;
    }
    
    &::placeholder {
        color: $color__gray--1;
        opacity: 1;
    }
}
%form__submit {
    padding: .1em 4em;
    margin: {
        top: 2em;
    }
    border: {
        radius: .3em;
    }
    font: normal $fz__9 $GRegular;
}


/* 
* HOME
------------------------------------------------------------ */
$slogan__arrow-w: 30px !default;
$slogan__title-w: 70% !default;
$slogan__title-fz: $fz__15 !default;
$slogan__title-bg: $color__orange !default;
$slogan__title-p: .6em 0 .6em 16% !default;
$slogan__note-fz: .6em !default;
$slogan__content-w: 100% - $slogan__title-w !default; 
$slogan__content-ml: 1em !default; 
$slogan__content-bg: $color__gray--3 !default; 
$slogan__content-p: 1.15em 0 1.15em 4em !default; 
$slogan__content-fz: $fz__13 !default; 


/* 
* ABOUT
------------------------------------------------------- */
$about__wrap-plr: 5% !default;
$about__wrap--left-color: $color__gray--6 !default;

$about__title--big-fz: $fz__70 !default;
$about__title--big-color: $color__gray--6 !default;
$about__title--normal-fz: $fz__15 !default;
$about__title--normalStrong-fz: 2em !default;
$about__title--normal-color: $color__gray--1 !default;

$about__content-fz: $fz__12 !default;
$about__content-mt: 2em !default;

$why__title-fz: $fz__20 !default;
$why__content-fz: $fz__12 !default;
$why__content-mt: 4em !default;
$why__owlDots-top: 5em !default;
$why__owlDot-color: $color__gray--4 !default;


/* 
* PRODUCTS
---------------------------------------------------------------- */
$productsSlider-maxW: 1000px !default;
$product__clearH--1: 1em !default;
$product__clearH--2: 37.8px !default;

$product__distance-mt: 4em !default;

/* 
* BLOG
---------------------------------------------------------------------- */
$post__img-h: 240px !default;
$post__wrap-p: 1em !default;
$post__wrap-bg: $color__gray--6 !default;
$post__description-mb: 2em !default;
$post__description-fz: $fz__9 !default;
$post__description-color: $color__gray--2 !default;
$post__transition-teming: .6s !default;

$post__content-color: #808285 !default;

/* 
* CONTACT
----------------------------------------------------------------- */
$contact-plr: .5em !default;
$contact_input-text-tel-ml: 2% !default;